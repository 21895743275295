export const USER_OS = {
    WIN: 'Windows',
    MAC: 'Mac OS',
    LINUX: 'Linux',
    IOS: 'iOS',
    ANDROID: 'Android',
    UNKNOWN: 'Unknown'
};

export const COUNTRY_CODE = {
    USA: 'US',
    CANADA: 'CA',
    AUSTRALIA: 'AU',
    WORLD: 'WR'
};

export const GOOGLE_IMAGERY_SATELLITE = 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}?v=1';

// Extent with buffer
export const COUNTRY_EXTENT = {
    [COUNTRY_CODE.USA]: [-183.1640625, -3.513421045640032, -38.3203125, 83.9422719152186],
    [COUNTRY_CODE.CANADA]: [-183.1640625, -3.513421045640032, -38.3203125, 83.9422719152186],
    [COUNTRY_CODE.AUSTRALIA]: [110, -45, 155, -5],
    [COUNTRY_CODE.WORLD]: [-180, -90, 180, 90]
};

export const COUNTRY_CENTER = {
    [COUNTRY_CODE.USA]: [-104, 39],
    [COUNTRY_CODE.CANADA]: [-104, 39],
    [COUNTRY_CODE.AUSTRALIA]: [133, -25],
    [COUNTRY_CODE.WORLD]: [-104, 39]
};

export const allowedCountryCodes = ['US', 'AU', 'CA'];

// these units are in meters for EPSG:3857 projection, they could be different in different projections.
// So if in the future we use a different projection than these units should also be changed
export const BUFFER = {
    GIC: 2400,
    NEARMAP: 1800,
    TILESERVER: 712.5, // 0.75 km
    MANUAL_MEASUREMENT_TILESERVER: 95 // 0.1 km
};

export const REQUEST_STATUS_ENUM = {
    DRAFT: 1,
    IN_PROGRESS: 2,
    COMPLETED: 3,
    FAILED: 4,
    QUEUED: 5,
    INVESTIGATING: 6,
    RESUBMIT: 7,
    CANCELLED: 8
};

export const FOLDER_STATUS_ENUM = {
    TO_BE_REVIEWED: 1,
    IN_PROGRESS: 2
};

export const REQUEST_STATUS = {
    [REQUEST_STATUS_ENUM.DRAFT]: 'Draft',
    [REQUEST_STATUS_ENUM.IN_PROGRESS]: 'In Progress',
    [REQUEST_STATUS_ENUM.INVESTIGATING]: 'In Progress',
    [REQUEST_STATUS_ENUM.RESUBMIT]: 'Resubmitted',
    [REQUEST_STATUS_ENUM.FAILED]: 'Failed',
    [REQUEST_STATUS_ENUM.COMPLETED]: 'Completed',
    [REQUEST_STATUS_ENUM.QUEUED]: 'Queued',
    [REQUEST_STATUS_ENUM.CANCELLED]: 'Cancelled'
};

export const FOLDER_STATUS = {
    [FOLDER_STATUS_ENUM.TO_BE_REVIEWED]: 'To Be Reviewed',
    [FOLDER_STATUS_ENUM.IN_PROGRESS]: 'In Progress'
};

export const REQUEST_STATUS_COLOR = {
    [REQUEST_STATUS_ENUM.DRAFT]: '#FFA700',
    [REQUEST_STATUS_ENUM.IN_PROGRESS]: '#4361ee',
    [REQUEST_STATUS_ENUM.INVESTIGATING]: '#4361ee',
    [REQUEST_STATUS_ENUM.RESUBMIT]: '#4361ee',
    [REQUEST_STATUS_ENUM.FAILED]: '#FF7474',
    [REQUEST_STATUS_ENUM.COMPLETED]: '#2DCA73',
    [REQUEST_STATUS_ENUM.QUEUED]: '#FF7D51',
    [REQUEST_STATUS_ENUM.CANCELLED]: '#999999'
};

export const FOLDER_STATUS_COLOR = {
    [FOLDER_STATUS_ENUM.TO_BE_REVIEWED]: '#FFA700',
    [FOLDER_STATUS_ENUM.IN_PROGRESS]: '#4361ee'
};

export const BLUEPRINT_REQUEST_STATUS_ENUM = {
    DRAFT: 1,
    PROCESSING: 2,
    IN_REVIEW: 3,
    IN_PROGRESS: 4,
    COMPLETED: 5,
    FAILED: 6,
    CLIENT_REVIEW: 7,
    RESUBMITTED: 8,
    QUEUED: 9
};
export const BLUEPRINT_REQUEST_POLL_STATUS_LIST = [
    BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED,
    BLUEPRINT_REQUEST_STATUS_ENUM.CLIENT_REVIEW,
    BLUEPRINT_REQUEST_STATUS_ENUM.FAILED,
    BLUEPRINT_REQUEST_STATUS_ENUM.QUEUED
];
export const RESUBMIT_TYPES = {
    NONE: 0,
    CORRECTION: 1,
    DUPLICATE: 2,
    ADDNEDUM: 3
};
export const RESUBMIT_STATUS = {
    PROCESSING: 1,
    IN_REVIEW: 2,
    IN_PROGRESS: 3,
    COMPLETED: 4
};
export const REQUIREMENT_FILE_EXTENSION = ['.doc', '.docx', '.txt', '.csv', '.xlsx', '.pdf'];

export const UPLOAD_IMAGE_CONTAINER_TITLE =
    'Please attach any screenshots which can help us better understand your requirements.';
export const UPLOAD_IMAGE_TITLE = 'Upload photo';
export const UPLOAD_IMAGE_META_INFO = '(PNG, JPG up-to 2 MB)';
export const UPLOAD_IMAGE_FILE_EXTENSION = ['.png', '.jpeg'];

export const RESUBMIT_NAME_MAPPING = {
    [RESUBMIT_TYPES.CORRECTION]: 'CORRECTIONS REQUESTED',
    [RESUBMIT_TYPES.ADDNEDUM]: 'RESUBMIT',
    [RESUBMIT_TYPES.DUPLICATE]: 'RESUBMITTED'
};
export const CORRECTION_TYPE_TITLE = {
    [RESUBMIT_TYPES.CORRECTION]:
        'Please enter the scope of the corrections or any new features to be captured in the existing sheets.',
    [RESUBMIT_TYPES.ADDNEDUM]:
        'Please enter the scope of takeoff. You will be asked to upload the plans in the next step.',
    [RESUBMIT_TYPES.DUPLICATE]: 'Please enter the changed scope of the takeoff.'
};

export const BLUEPRINT_REQUEST_STATUS = {
    [BLUEPRINT_REQUEST_STATUS_ENUM.DRAFT]: 'Draft',
    [BLUEPRINT_REQUEST_STATUS_ENUM.PROCESSING]: 'Processing',
    [BLUEPRINT_REQUEST_STATUS_ENUM.IN_REVIEW]: 'In Review',
    [BLUEPRINT_REQUEST_STATUS_ENUM.IN_PROGRESS]: 'In Progress',
    [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED]: 'Completed',
    [BLUEPRINT_REQUEST_STATUS_ENUM.FAILED]: 'Failed',
    [BLUEPRINT_REQUEST_STATUS_ENUM.CLIENT_REVIEW]: 'Client Review',
    [BLUEPRINT_REQUEST_STATUS_ENUM.RESUBMITTED]: 'Resubmitted',
    [BLUEPRINT_REQUEST_STATUS_ENUM.QUEUED]: 'Queued'
};

export const BLUEPRINT_REQUEST_STATUS_COLOR = {
    [BLUEPRINT_REQUEST_STATUS_ENUM.DRAFT]: '#4361ee',
    [BLUEPRINT_REQUEST_STATUS_ENUM.PROCESSING]: '#FFA700',
    [BLUEPRINT_REQUEST_STATUS_ENUM.IN_REVIEW]: '#FF7D51',
    [BLUEPRINT_REQUEST_STATUS_ENUM.IN_PROGRESS]: '#4361ee',
    [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED]: '#2DCA73',
    [BLUEPRINT_REQUEST_STATUS_ENUM.FAILED]: '#FF7474',
    [BLUEPRINT_REQUEST_STATUS_ENUM.CLIENT_REVIEW]: '#FF7D51',
    [BLUEPRINT_REQUEST_STATUS_ENUM.RESUBMITTED]: '#CC7A00',
    [BLUEPRINT_REQUEST_STATUS_ENUM.QUEUED]: '#FF7D51'
};

export const TIMELINE_COMPONENTS_INDEX = {
    // Aerial Only
    NOT_MY_PROPERTY: 4,
    CANCELLED: 8,

    // Aerial & Blueprint Both
    PROPERTY_VIEW: 1, // Draft
    MEASUREMENTS: 2, // Completed
    WAITING_PANEL: 5, // In Progress
    FAILED: 6, // Failed
    QUEUED: 3,
    RESUBMITTED: 10,

    // Blueprint Only
    PROCESSING: 7, // Processing
    REVIEW: 8, // Review,
    CLIENT_REVIEW: 9 // Manual Review
};

export const MAP_LAYERS = {
    IMAGERY: 'image-layer',
    MARKER: 'marker-layer',
    OLD_PARCEL: 'Old-Parcel',
    PARCEL: 'Parcel',
    LABELS: 'labels-layer',
    NOTES: 'notes-layer',
    OUTPUT: 'output-layer',
    INITIAL_PARCEL: 'initial-parcel',
    ICON: 'icon-layer',
    ARROW: 'arrow-layer',
    MEASURE: 'measure-layer',
    ZONE: 'zone-layer',
    LABEL_BOXES: 'label-box-layer'
};

export const ZONE_ACTIONS = {
    CREATE: 0,
    UPDATE: 1,
    DELETE: 2
};

export const EDITABLE_LAYERS = [
    MAP_LAYERS.OUTPUT,
    MAP_LAYERS.PARCEL,
    MAP_LAYERS.ARROW,
    MAP_LAYERS.ICON,
    MAP_LAYERS.LABELS
];

export const EXTRACTION_STATUS_ENUM = {
    FETCHING_IMAGERY: 1,
    FETCHING_SIDE_VIEWS: 2,
    EXTRACTING_FEATURES: 3,
    GENERATING_MEASUREMENTS: 4
};

export const EXTRACTION_STATUS = {
    [EXTRACTION_STATUS_ENUM.FETCHING_IMAGERY]: 'Fetching imagery...',
    [EXTRACTION_STATUS_ENUM.FETCHING_SIDE_VIEWS]: 'Fetching side views...',
    [EXTRACTION_STATUS_ENUM.EXTRACTING_FEATURES]: 'Extracting property features...'
};

export const LOADING_OUTPUT_MSG = 'Loading your outputs...';

export const PARCEL_DEFAULT_STYLE = {
    color: '#4361ee',
    fillColor: '#4361ee33',
    is_visible: true,
    layer_order: 10,
    width: 3,
    opacity: 0.3
};

export const CUSTOM_FEATURE_DEFAULT_STYLE = {
    color: '#f69e9f',
    width: 3,
    opacity: 0.3,
    is_visible: true,
    layer_order: 1
};

export const TOOLS_ID = {
    LABEL: 1,
    ADD_FEATURE: 2,
    EDIT_POLYGON: 3,
    ADD_PARCEL: 4,
    EDIT_PARCEL: 5,
    DELETE_PARCEL: 6,
    DELETE_FEATURE: 7,
    LASSO_RECLASSIFY: 8,
    DRAW_HOLE: 10,
    SPLIT: 11,
    RECLASSIFIER: 12,
    NEARBY_PARCEL: 13,
    MEASURE_TOOL: 14,
    ADD_ARROW: 15,
    UPLOAD_ICON: 16,
    NOTES_TOOL: 17,
    SCALE_LINE_TOOL: 18,
    ADD_RECTANGLE: 19,
    ADD_CIRCLE: 20,
    FREE_HAND: 21,
    SELECT_TOOL: 22,
    ROTATE_CLOCKWISE: 23,
    ROTATE_COUNTER_CLOCKWISE: 24,
    FLIP_HORIZONTAL: 25,
    FLIP_VERTICAL: 26,
    MOVE_FEATURE: 27,
    CUT_TOOL: 28,
    COPY_TOOL: 29,
    PASTE_TOOL: 30,
    ZONE_DIVISION_TOOL: 31,
    CIRCLE_HOLE: 32,
    ADD_CURVE: 33,
    FILL_TOOL: 34,
    LASSO_SELECT: 35,
    TAG_TOOL: 36,
    LASSO_TAG: 37,
    ZONE_MERGE_TOOL: 38,
    LABEL_BOX: 39,
    ROTATE_TOOL: 40
};

export const TOOL_TITLE = {
    [TOOLS_ID.LABEL]: 'How to add labels?',
    [TOOLS_ID.LABEL_BOX]: 'How to add label box?',
    [TOOLS_ID.ADD_FEATURE]: 'How to add a property feature?',
    [TOOLS_ID.EDIT_POLYGON]: 'How to edit property features?',
    [TOOLS_ID.ADD_PARCEL]: 'How to draw a lot boundary?',
    [TOOLS_ID.EDIT_PARCEL]: 'How to edit a lot boundary?',
    [TOOLS_ID.DELETE_PARCEL]: 'How to delete a lot boundary?',
    [TOOLS_ID.DELETE_FEATURE]: 'How to delete a property feature?',
    [TOOLS_ID.LASSO_RECLASSIFY]: 'How to use lasso reclassify tool to delete and reclassify property features?',
    [TOOLS_ID.DRAW_HOLE]: 'How to cut a polygon hole?',
    [TOOLS_ID.CIRCLE_HOLE]: 'How to cut a circular hole?',
    [TOOLS_ID.SPLIT]: 'How to split property features?',
    [TOOLS_ID.RECLASSIFIER]: 'How to reclassify property features?',
    [TOOLS_ID.NEARBY_PARCEL]: 'How to add a nearby place?',
    [TOOLS_ID.MEASURE_TOOL]: 'How to measure area and perimeter on map?',
    [TOOLS_ID.ADD_ARROW]: 'How to add arrows?',
    [TOOLS_ID.UPLOAD_ICON]: 'How to add icons?',
    [TOOLS_ID.NOTES_TOOL]: 'How to add a note?',
    [TOOLS_ID.SCALE_LINE_TOOL]: 'How to measure scale manually?',
    [TOOLS_ID.ADD_RECTANGLE]: 'How to add a rectangular/square feature?',
    [TOOLS_ID.ADD_CIRCLE]: 'How to add a circular/elliptical feature?',
    [TOOLS_ID.FILL_TOOL]: 'How to add a fill feature?',
    [TOOLS_ID.ADD_CURVE]: 'How to add a curved feature?',
    [TOOLS_ID.FREE_HAND]: 'How to draw a feature by free hand tool?',
    [TOOLS_ID.SELECT_TOOL]: 'How to use select tool?',
    [TOOLS_ID.MOVE_FEATURE]: 'How to move any property feature?',
    [TOOLS_ID.ZONE_DIVISION_TOOL]: 'How to create/edit zones?',
    [TOOLS_ID.TAG_TOOL]: 'How to use Tag tool',
    [TOOLS_ID.LASSO_TAG]: 'How to use Lasso Tag tool',
    [TOOLS_ID.ZONE_MERGE_TOOL]: 'How to merge zones?',
    [TOOLS_ID.ROTATE_TOOL]: 'How to rotate property features?'
};

export const TIMELINE_COMPONENT_TITLE = {
    [TIMELINE_COMPONENTS_INDEX.PROPERTY_VIEW]: 'Takeoff Details',
    [TIMELINE_COMPONENTS_INDEX.MEASUREMENTS]: 'Measurements',
    [TIMELINE_COMPONENTS_INDEX.RESUBMITTED]: 'Measurements',
    [TIMELINE_COMPONENTS_INDEX.NOT_MY_PROPERTY]: 'Takeoff',
    [TIMELINE_COMPONENTS_INDEX.WAITING_PANEL]: 'Measurement In Progress',
    [TIMELINE_COMPONENTS_INDEX.FAILED]: 'Measurement Failed',
    [TIMELINE_COMPONENTS_INDEX.CANCELLED]: 'Measurement Cancelled',
    [TIMELINE_COMPONENTS_INDEX.QUEUED]: 'Request added to queue'
};

export const BUFFER_PERCENT = 10;

export const SIDE_VIEWS_MAP = {
    NORTH: 1,
    SOUTH: 2,
    EAST: 3,
    WEST: 4
};

export const SIDE_VIEWS = {
    [SIDE_VIEWS_MAP.NORTH]: 'North',
    [SIDE_VIEWS_MAP.SOUTH]: 'South',
    [SIDE_VIEWS_MAP.EAST]: 'East',
    [SIDE_VIEWS_MAP.WEST]: 'West'
};

export const USER_ROLE = {
    ADMIN: 1,
    TEAM_MEMBER: 2,
    TEAM_ADMIN: 3
};

interface RoleIdentifierType {
    [key: string]: string;
}

export const ROLE_IDENTIFIER: RoleIdentifierType = {
    1: 'SUPER ADMIN',
    2: 'TEAM MEMBER',
    3: 'ADMIN'
};

export const KNOWN_EXCEPTIONS = {
    PROPERTY_NOT_FOUND: 'ParcelNotFoundException',
    IMAGERY_NOT_FOUND: 'ImageryNotFoundException',
    PLAN_OVER: 'PlanOverException',
    FREE_PROPERTY_OVER: 'FreePropertyOverException',
    PROPERTY_TOO_LARGE: 'PropertyTooLargeException',
    QUEUE_FULL: 'QueueFullException',
    QUEUE_MISMATCH: 'RequestIdNotInQueueException',
    FREE_PROPERTY_SIZE_EXCEED: 'FreePropertySizeException',
    DRAFT_EXCEED: 'MaxDraftRequestException',
    FREE_TRIAL_EXHAUST: 'UnitsExhaustedException',
    UNITS_EXHAUST_SUBS: 'UnitsExhaustedInSubscriptionException',
    NO_SUFFICIENT_UNIT: 'NoSufficientUnitsException',
    WRONG_REPORT: 'ReportTypeException',
    NO_ACTIVE_USAGE: 'NoUsageSummaryException',
    SOCIAL_SIGNUP_EXCEPTION: 'SocialSignUpException',
    US_SIGNUP_RESTRICTION: 'RestrictSignUpException',
    US_LAWNS_EXCEPTION: 'USLawnsException',
    SESSION_CLOSED: 'SessionClosedException'
};

export const TRANSACTION_STATUS_ENUM = {
    PENDING: 1,
    SUCCESS: 2,
    FAILED: 3
};

export const MAP_VIEW = { MAP: 1, SATELLITE: 2 };

export const TRANSACTION_STATUS = {
    [TRANSACTION_STATUS_ENUM.PENDING]: 'Pending',
    [TRANSACTION_STATUS_ENUM.FAILED]: 'Failed',
    [TRANSACTION_STATUS_ENUM.SUCCESS]: 'Success'
};

export const TRANSACTION_STATUS_COLOR = {
    [TRANSACTION_STATUS_ENUM.PENDING]: '#FFC212',
    [TRANSACTION_STATUS_ENUM.FAILED]: '#FF7474',
    [TRANSACTION_STATUS_ENUM.SUCCESS]: '#2DCA73'
};

export const EMAIL_TOPICS = {
    ADDRESS_NOT_FOUND: 1,
    IMAGERY_NOT_FOUND: 2,
    NOT_MY_PROPERTY: 3,
    OTHER: 4,
    PROPERTY_TOO_LARGE: 5
};

export const TOOLBAR_MODAL_TYPES = {
    DELETE_PARCEL: 1,
    ADDRESS_NOT_FOUND: 2,
    DELETE_PARCEL_BEFORE_DRAW: 3,
    RESET_OUTPUTS: 4,
    CONFIRM_ADD_FEATURE: 5
};

export const INDUSTRY_TYPES = {
    LANDSCAPE_MAINTENANCE: 1,
    PAVING_MAINTENANCE: 2,
    LANDSCAPE_CONSTRUCTION: 3,
    PAVING_CONSTRUCTION: 4,
    GENERAL_CONSTRUCTION: 5,
    SNOW_REMOVAL: 6
};

export const INDUSTRY_TYPES_NAME = {
    [INDUSTRY_TYPES.LANDSCAPE_MAINTENANCE]: 'Landscape Maintenance',
    [INDUSTRY_TYPES.PAVING_MAINTENANCE]: 'Paving Maintenance',
    [INDUSTRY_TYPES.LANDSCAPE_CONSTRUCTION]: 'Landscape Construction',
    [INDUSTRY_TYPES.PAVING_CONSTRUCTION]: 'Paving Construction',
    [INDUSTRY_TYPES.GENERAL_CONSTRUCTION]: 'General Construction',
    [INDUSTRY_TYPES.SNOW_REMOVAL]: 'Snow Removal'
};

export const GEOMETRY_TYPE_ENUM = {
    POINT: 1,
    LINESTRING: 2,
    POLYGON: 3,
    NUMERICAL: 4
};

export const TAG_PANEL_MODE = {
    ASSIGN: 1,
    VIEW: 2,
    EDIT: 3
};

export const FEATURE_MODE = {
    EDIT: 'EDITING',
    VIEW: 'VIEWING',
    CREATE: 'CREATING'
};
export const FEATURE_VIEW_TYPE = {
    DEFAULT: 'DEFAULT',
    TAB: 'TAB'
};
export const FEATURE_ACTION_TYPE = {
    SAVE_AND_EXIT: 'SAVE_AND_EXIT',
    SAVE_AND_CREATE_ANOTHER: 'SAVE_AND_CREATE_ANOTHER',
    CANCEL: 'CANCEL'
};

export const typeOptions = [
    {
        value: 1,
        label: 'POINT'
    },
    {
        value: 2,
        label: 'EDGE'
    },
    {
        value: 3,
        label: 'SURFACE'
    },
    {
        value: 4,
        label: 'NUMERICAL'
    }
];
export const NUMERICAL_MEASURE_TYPE = {
    COUNT: 1,
    LENGTH: 2,
    AREA: 3,
    VOLUME: 4,
    WEIGHT: 5,
    WEIGHT_TON: 6
};

export const measureTypeOptions = [
    {
        label: 'Count',
        value: NUMERICAL_MEASURE_TYPE.COUNT
    },
    {
        label: 'Area',
        value: NUMERICAL_MEASURE_TYPE.AREA
    },
    {
        label: 'Length',
        value: NUMERICAL_MEASURE_TYPE.LENGTH
    },
    {
        label: 'Volume',
        value: NUMERICAL_MEASURE_TYPE.VOLUME
    },
    {
        label: 'Weight',
        value: NUMERICAL_MEASURE_TYPE.WEIGHT
    },
    {
        label: 'Weight Ton',
        value: NUMERICAL_MEASURE_TYPE.WEIGHT_TON
    }
];
export const NUMERICAL_MEASURE_TYPE_NAMES = {
    [NUMERICAL_MEASURE_TYPE.COUNT]: 'Count',
    [NUMERICAL_MEASURE_TYPE.AREA]: 'Area',
    [NUMERICAL_MEASURE_TYPE.LENGTH]: 'Length',
    [NUMERICAL_MEASURE_TYPE.VOLUME]: 'Volume',
    [NUMERICAL_MEASURE_TYPE.WEIGHT]: 'Weight',
    [NUMERICAL_MEASURE_TYPE.WEIGHT_TON]: 'Weight Ton'
};

export const ALL_GEOMETRY_TYPES = {
    POINT: 'Point',
    LINE_STRING: 'LineString',
    LINEAR_RING: 'LinearRing',
    POLYGON: 'Polygon',
    MULTI_POINT: 'MultiPoint',
    MULTI_LINE_STRING: 'MultiLineString',
    MULTI_POLYGON: 'MultiPolygon',
    GEOMETRY_COLLECTION: 'GeometryCollection',
    CIRCLE: 'Circle'
};
export const GEOMETRY_TYPE = {
    [GEOMETRY_TYPE_ENUM.POINT]: 'Point',
    [GEOMETRY_TYPE_ENUM.LINESTRING]: 'LineString',
    [GEOMETRY_TYPE_ENUM.POLYGON]: 'Polygon',
    [GEOMETRY_TYPE_ENUM.NUMERICAL]: 'Point'
};
export const GEOMETRY_NAME_SINGLE = {
    [GEOMETRY_TYPE_ENUM.POINT]: 'Point',
    [GEOMETRY_TYPE_ENUM.LINESTRING]: 'Edge',
    [GEOMETRY_TYPE_ENUM.POLYGON]: 'Surface',
    [GEOMETRY_TYPE_ENUM.NUMERICAL]: 'Numerical'
};

export const GEOMETRY_TYPE_NAME = {
    [GEOMETRY_TYPE_ENUM.POINT]: 'Points',
    [GEOMETRY_TYPE_ENUM.LINESTRING]: 'Edges',
    [GEOMETRY_TYPE_ENUM.POLYGON]: 'Surfaces',
    [GEOMETRY_TYPE_ENUM.NUMERICAL]: 'Numericals'
};
export const GEOMENTRY_TYPE_NAME_TO_ENUM = {
    points: GEOMETRY_TYPE_ENUM.POINT,
    lines: GEOMETRY_TYPE_ENUM.LINESTRING,
    polygons: GEOMETRY_TYPE_ENUM.POLYGON,
    abstracts: GEOMETRY_TYPE_ENUM.NUMERICAL
};
export const LINE_UNITS = ['ft.', 'm', 'ft'];
export const AREA_UNITS = ['sq ft', 'sq m', 'sqft', 'sqm'];
export const VOLUME_UNITS = ['cu yd', 'm3', 'cuyd', 'cum'];
export const WEIGHT_UNITS = ['lb', 'lbs', 'kg'];
export const WEIGHT_TON_UNITS = ['tn', 't'];
export const COUNT_UNITS = ['Number'];
export const MATERIAL_UNIT_REVERSE_MAPPING = {
    m: 5,
    sqm: 6,
    cum: 7,
    count: 4,
    ft: 1,
    sqft: 2,
    cuyd: 3
};

export const GEOMETRY_TYPE_STRING = {
    POINT: 'Point',
    CIRCLE: 'Circle',
    LINESTRING: 'LineString',
    POLYGON: 'Polygon'
};

export const OBLIQUE_ROTATION = {
    [SIDE_VIEWS_MAP.NORTH]: 0,
    [SIDE_VIEWS_MAP.SOUTH]: 180,
    [SIDE_VIEWS_MAP.EAST]: 90,
    [SIDE_VIEWS_MAP.WEST]: 270
};

export const MAP_ROTATION = {
    [SIDE_VIEWS_MAP.NORTH]: 0,
    [SIDE_VIEWS_MAP.SOUTH]: 180,
    [SIDE_VIEWS_MAP.EAST]: 270,
    [SIDE_VIEWS_MAP.WEST]: 90
};

export const TILE_LOAD_BUFFER = 800;

export const FIXED_COST_TO_BE_ADDED = 0.8;

export const LIST_OF_PROPERTY_TABS = {
    MY_REQUESTS: 'mr',
    MY_QUEUE: 'qu',
    MY_TEAM_REQUESTS: 'team',
    MY_DRAFTS: 'draft',
    SHARED: 'shared',
    FOLDERS: 'portfolios'
};
export const PROPERTY_TABS_NAME = {
    [LIST_OF_PROPERTY_TABS.MY_REQUESTS]: 'Measurements',
    [LIST_OF_PROPERTY_TABS.MY_QUEUE]: 'Queue',
    [LIST_OF_PROPERTY_TABS.MY_TEAM_REQUESTS]: 'Team',
    [LIST_OF_PROPERTY_TABS.MY_DRAFTS]: 'Draft',
    [LIST_OF_PROPERTY_TABS.SHARED]: 'Shared with me',
    [LIST_OF_PROPERTY_TABS.FOLDERS]: 'Bulk Takeoffs'
};
export const BEAM_PROPERTY_TABS_NAME = {
    [LIST_OF_PROPERTY_TABS.MY_REQUESTS]: 'My Takeoffs',
    [LIST_OF_PROPERTY_TABS.MY_QUEUE]: 'Queue',
    [LIST_OF_PROPERTY_TABS.MY_TEAM_REQUESTS]: 'Team',
    [LIST_OF_PROPERTY_TABS.MY_DRAFTS]: 'Draft',
    [LIST_OF_PROPERTY_TABS.SHARED]: 'Shared with me',
    [LIST_OF_PROPERTY_TABS.FOLDERS]: 'Portfolios'
};
export const EXPORT_OUTPUT_ENUM = {
    LINK: 0,
    PDF: 1,
    EXCEL: 2,
    JPEG: 3,
    CSV: 5,
    ALL: 6,
    SCREENSHOT: 7,
    SUPPLEMENTARY: 8
};

export const EXPORT_OUTPUT_NAME_ENUM = {
    [EXPORT_OUTPUT_ENUM.PDF]: 'PDF',
    [EXPORT_OUTPUT_ENUM.EXCEL]: 'Excel',
    [EXPORT_OUTPUT_ENUM.JPEG]: 'JPEG',
    [EXPORT_OUTPUT_ENUM.CSV]: 'CSV',
    [EXPORT_OUTPUT_ENUM.ALL]: 'ALL',
    [EXPORT_OUTPUT_ENUM.SUPPLEMENTARY]: 'SUPPLEMENTARY'
};

export const PAPER_SIZES = {
    A4: 1,
    A3: 2,
    TTS: 3 //true to scale
};

export const PAPER_SIZES_TITLE = {
    [PAPER_SIZES.A3]: 'A3',
    [PAPER_SIZES.A4]: 'A4',
    [PAPER_SIZES.TTS]: 'True to Scale'
};

export const PAPER_SIZE_OPTIONS = [
    { label: PAPER_SIZES_TITLE[PAPER_SIZES.TTS], value: PAPER_SIZES.TTS },
    { label: PAPER_SIZES_TITLE[PAPER_SIZES.A3], value: PAPER_SIZES.A3 },
    { label: PAPER_SIZES_TITLE[PAPER_SIZES.A4], value: PAPER_SIZES.A4 }
];

export const PAPER_SIZES_NOTES = {
    [PAPER_SIZES.A3]: 'Exports with the plan and legend visible on a A3-sized page.',
    [PAPER_SIZES.A4]:
        'Exports with the plan and legend visible on a standard A4-sized page. Additionally, there will be a separate file containing feature descriptions and measurements.',
    [PAPER_SIZES.TTS]:
        'A PDF file that has the takeoff annotations superimposed on the original plan visible on the full page. The legend, feature descriptions, and measurements will be provided in a separate file.'
};

export const EXPORT_TYPE_ENUM = {
    STANDARD: 'standard',
    ZONES: 'zonal',
    GROUPS: 'groups'
};

export const INVITE_MODAL_TYPES = {
    CONFIRM_ADD: 1,
    CONFIRM_REMOVE: 2,
    CONFIRM_CANCEL: 3
};

export const PLAN_MODAL_TYPES = {
    CONFIRM_UNSUBSCRIBE: 1,
    CHANGE_PLAN: 2,
    UNSUBSCRIBE_THANKS: 3,
    CHANGE_PLAN_THANKS: 4,
    CONTACT_US_THANKS: 5
};

export const COMPANY_TYPE = {
    ENTERPRISE: 1, // Enterprise
    NON_ENTERPRISE: 2 // Standard
};

export const QUANTITY_UNIT = {
    KG: 1,
    GRAM: 2,
    LB: 3,
    OZ: 4
};

export const QUANTITY_UNIT_LB = {
    [QUANTITY_UNIT.KG]: 'kg',
    [QUANTITY_UNIT.GRAM]: 'gram',
    [QUANTITY_UNIT.LB]: 'lb',
    [QUANTITY_UNIT.OZ]: 'oz',
    all: [QUANTITY_UNIT.KG, QUANTITY_UNIT.GRAM, QUANTITY_UNIT.LB, QUANTITY_UNIT.OZ]
};
export const DEPTH_UNIT = {
    IN: 1,
    CM: 2,
    1: 'in',
    2: 'cm'
};
export const MEASUREMENT_UNIT = {
    ACRES: 1,
    SQFT: 2,
    SQYD: 3,
    M2: 4,
    FT: 5,
    YD: 6,
    M: 7,
    CUFT: 8,
    CUYD: 9,
    M3: 10,
    COUNT: 11
};
export const MEASUREMENT_UNIT_LB = {
    [MEASUREMENT_UNIT.ACRES]: 'acre',
    [MEASUREMENT_UNIT.SQFT]: 'sqft',
    [MEASUREMENT_UNIT.SQYD]: 'sqyd',
    [MEASUREMENT_UNIT.M2]: 'm2',
    [MEASUREMENT_UNIT.FT]: 'ft',
    [MEASUREMENT_UNIT.YD]: 'yd',
    [MEASUREMENT_UNIT.M]: 'm',
    [MEASUREMENT_UNIT.CUFT]: 'cuft',
    [MEASUREMENT_UNIT.CUYD]: 'cuyd',
    [MEASUREMENT_UNIT.M3]: 'm3',
    [MEASUREMENT_UNIT.COUNT]: 'count',
    depth: [MEASUREMENT_UNIT.CUFT, MEASUREMENT_UNIT.CUYD, MEASUREMENT_UNIT.M3],
    all: [
        MEASUREMENT_UNIT.ACRES,
        MEASUREMENT_UNIT.SQFT,
        MEASUREMENT_UNIT.SQYD,
        MEASUREMENT_UNIT.M2,
        MEASUREMENT_UNIT.FT,
        MEASUREMENT_UNIT.YD,
        MEASUREMENT_UNIT.M,
        MEASUREMENT_UNIT.CUFT,
        MEASUREMENT_UNIT.CUYD,
        MEASUREMENT_UNIT.M3,
        MEASUREMENT_UNIT.COUNT
    ]
};

// This constant should be in sync with @measurement-panel-width in variables.less
export const MEASUREMENT_PANEL_WIDTH = 420;
export const FILE_PANEL_WIDTH = 232;
export const FILE_PANEL_WIDTH_LARGE = 300;
export const NOTES_PANEL_WIDTH = 360;

export const MEASUREMENT_SHAPE = {
    POLYGON: 1,
    LINE: 2,
    CIRCLE: 3
};

export const MAP_BTNS_DEFAULT_BOTTOM = 200;

export const PURCHASE_STATUS_ENUM = {
    UN_BILLED: 1,
    PROCESSING: 2,
    PAID: 3,
    FAILED: 4
};

export const PURCHASE_STATUS = {
    [PURCHASE_STATUS_ENUM.UN_BILLED]: 'Unbilled',
    [PURCHASE_STATUS_ENUM.PAID]: 'Paid',
    [PURCHASE_STATUS_ENUM.PROCESSING]: 'Processing',
    [PURCHASE_STATUS_ENUM.FAILED]: 'Failed'
};

export const PURCHASE_STATUS_COLOR = {
    [PURCHASE_STATUS_ENUM.UN_BILLED]: '#FFC212',
    [PURCHASE_STATUS_ENUM.PAID]: '#2DCA73',
    [PURCHASE_STATUS_ENUM.PROCESSING]: '#FFC212',
    [PURCHASE_STATUS_ENUM.FAILED]: '#FF7474'
};

export const IFRAME_ERROR_TYPE = {
    TOKEN_EXPIRED: 'TOKEN_EXPIRED',
    INVALID_USER: 'INVALID_USER',
    INVALID_REQUEST: 'INVALID_REQUEST'
};

export const VALIDATE_NAME_ERROR = 'Only letters, numbers, _ and - is allowed';
export const FEATURE_NAME_LENGTH_ERROR = 'Maximum 31 characters are allowed';
export const EMPTY_FEATURE_NAME_ERROR = 'Property-feature name cannot be empty';
export const TAKEOFF_TYPE_ERROR = 'Please select at least one takeoff type';

export const LAYER_INDEX = {
    DEFAULT: 1,
    IMAGERY: 1,
    POLYGON: 10,
    LINESTRING: 11,
    POINT: 12,
    PARCEL: 10,
    NEARBY_PARCEL: 10,
    ICON: 11,
    ARROW: 12,
    LABEL: 19,
    DRAW: 20,
    NOTES: 21,
    ZONE: 22,

    // also support api constants
    [GEOMETRY_TYPE_ENUM.POLYGON]: 10,
    [GEOMETRY_TYPE_ENUM.LINESTRING]: 11,
    [GEOMETRY_TYPE_ENUM.POINT]: 12
};

export const IMAGERY_DEFAULT_DATE = '2021-01-01';

export const AI_EVENT_TYPE = {
    TOOL_OFF: 'TOOL-OFF'
};
export const INCH_TO_KM = 0.0000254; // 1 inch is 0.0000254 km

export const HUBSPOT_PORTAL_ID = '20155995';
export const HUBSPOT_COOKIE_NAME = 'hubspotutk';
export const HUBSPOT_WELCOME_FORM_GUID = 'ae49da24-a73b-4dcb-9e60-8be6613faf8d';

export const USAGE_MODE = {
    AERIAL: 1,
    BLUEPRINT: 2,
    BOTH: 3
};

export const USAGE_MODE_NAME = {
    [USAGE_MODE.AERIAL]: 'Aerial takeoff',
    [USAGE_MODE.BLUEPRINT]: 'Blueprint takeoff'
};

export const BP_MAX_FILE_SIZE = 5;
export const BP_MAX_COMBINED_FILES_SIZE = 250;
export const BP_MAX_CORRECTION_FILE_SIZE = 2;

export const TICKET_SOURCE_MODE = {
    AERIAL: 1,
    BLUEPRINT: 2
};

export const TICKET_STATUS_ENUM = {
    PENDING: 1,
    RESOLVED: 2
};

export const TICKET_STATUS = {
    [TICKET_STATUS_ENUM.PENDING]: 'Pending',
    [TICKET_STATUS_ENUM.RESOLVED]: 'Resolved'
};

export const TICKET_STATUS_COLOR = {
    [TICKET_STATUS_ENUM.PENDING]: '#FF9800',
    [TICKET_STATUS_ENUM.RESOLVED]: '#4CBB7F'
};

export const TICKET_SUBJECT = {
    1: 'Address not found',
    2: 'Need assistance with a takeoff',
    3: 'How to go about HOA measurements?',
    4: 'How to resubmit a takeoff?',
    5: 'ETA of requests',
    6: 'Need assistance with a takeoff',
    7: 'How to invite users to the platform?',
    8: 'How to change admin account?',
    9: 'Payment related issue',
    10: 'Others',
    13: 'Request a feature',
    14: 'Attentive missed something'
};

export const TICKET_SUBJECT_TAKEOFF = {
    1: 'Address not found',
    2: 'Need assistance with a takeoff',
    3: 'How to go about HOA measurements?',
    4: 'How to resubmit a takeoff?',
    14: 'Attentive missed something'
};

export const TICKET_SUBJECT_REQUEST = {
    5: 'ETA of requests'
};

export const TICKET_SUBJECT_PLATFORM = {
    6: 'Need assistance with a takeoff',
    7: 'How to invite users to the platform?',
    8: 'How to change admin account?'
};

export const TICKET_SUBJECT_PAYMENT = {
    9: 'Payment related issue',
    10: 'Others'
};

export const BLUEPRINT_TICKET_SUBJECT = {
    1: 'Address not found',
    2: 'Need assistance with a takeoff',
    3: 'How to go about HOA measurements?',
    4: 'How to resubmit a takeoff?',
    5: 'ETA of requests',
    6: 'Need assistance with tools',
    7: 'How to invite users to the platform?',
    8: 'How to change admin account?',
    9: 'Payment related issue',
    10: 'Others',
    11: 'How to submit a take-off without legend',
    12: 'Request update in take-off',
    13: 'Request a feature'
};

export const BLUEPRINT_TICKET_TAKEOFF = {
    2: 'Need assistance with a takeoff',
    5: 'ETA of requests',
    11: 'How to submit a take-off without legend',
    12: 'Request update in take-off'
};

export const BLUEPRINT_TICKET_PLATFORM = {
    6: 'Need assistance with tools',
    7: 'How to invite users to the platform?',
    8: 'How to change admin account?'
};

export const BLUEPRINT_TICKET_PAYMENT = {
    9: 'Payment related issue',
    10: 'Others'
};
export const REQUEST_A_FEATURE = 13;
export const MIN_ROW_PROJECT_TABLE = 10;

export const BPT_DEFAULT_MEASUREMENT_PAGE_SIZE = 20;
export const BPT_MEASUREMENT_OPTION_LIST = [20, 50, 100];
export const AERIAL_MEASUREMENT_OPTION_LIST = [20, 50, 100];
export const AERIAL_TAB_PAGE_SIZE = 20;

export const EARNINGS = {
    MIN_EARNING: 0,
    MAX_EARNING: 500
};

export const REFERRAL_STATUS_ENUM = {
    EMAIL_SENT: 1,
    FREE_TRIAL_ACCOUNT_CREATED: 2,
    PRO_USER: 3
};

export const REFERRAL_STATUS = {
    [REFERRAL_STATUS_ENUM.EMAIL_SENT]: 'Email sent',
    [REFERRAL_STATUS_ENUM.FREE_TRIAL_ACCOUNT_CREATED]: 'Free Trial account created',
    [REFERRAL_STATUS_ENUM.PRO_USER]: 'Pro user'
};

export const REFERRAL_STATUS_COLOR = {
    [REFERRAL_STATUS_ENUM.EMAIL_SENT]: '#2DCA73',
    [REFERRAL_STATUS_ENUM.FREE_TRIAL_ACCOUNT_CREATED]: '#575757',
    [REFERRAL_STATUS_ENUM.PRO_USER]: '#4361ee'
};

export const EARNING_STATUS_ENUM = {
    NOT_AVAILABLE: 1,
    PENDING: 2,
    SENT: 3
};

export const EARNING_STATUS = {
    [EARNING_STATUS_ENUM.NOT_AVAILABLE]: 'N/A',
    [EARNING_STATUS_ENUM.PENDING]: 'Pending',
    [EARNING_STATUS_ENUM.SENT]: 'Sent'
};

export const EARNING_STATUS_COLOR = {
    [EARNING_STATUS_ENUM.NOT_AVAILABLE]: '#575757',
    [EARNING_STATUS_ENUM.PENDING]: '#ffa525',
    [EARNING_STATUS_ENUM.SENT]: '#2DCA73'
};

export const TAKEOFF_ACCESS = {
    OWNER: 1,
    EDITOR: 2,
    COMMENTOR: 3
};

export const TAKEOFF_ACCESS_ROLE = {
    [TAKEOFF_ACCESS.OWNER]: 'Owner',
    [TAKEOFF_ACCESS.EDITOR]: 'Editor',
    [TAKEOFF_ACCESS.COMMENTOR]: 'Commentor'
};

export const AERIAL_SHAREABLE_TAKEOFF_STATUS = [
    REQUEST_STATUS_ENUM.IN_PROGRESS,
    REQUEST_STATUS_ENUM.COMPLETED,
    REQUEST_STATUS_ENUM.QUEUED,
    REQUEST_STATUS_ENUM.RESUBMIT,
    REQUEST_STATUS_ENUM.INVESTIGATING,
    REQUEST_STATUS_ENUM.DRAFT
];

export const BLUEPRINT_SHAREABLE_TAKEOFF_STATUS = [
    BLUEPRINT_REQUEST_STATUS_ENUM.PROCESSING,
    BLUEPRINT_REQUEST_STATUS_ENUM.IN_REVIEW,
    BLUEPRINT_REQUEST_STATUS_ENUM.IN_PROGRESS,
    BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED,
    BLUEPRINT_REQUEST_STATUS_ENUM.RESUBMITTED,
    BLUEPRINT_REQUEST_STATUS_ENUM.QUEUED,
    REQUEST_STATUS_ENUM.DRAFT
];

export const TAKEOFF_EVENTS = {
    // Refresh output data
    OUTPUT_EDIT: 'OUTPUT_EDIT',
    AOI_EDIT: 'AOI_EDIT',
    REQUEST_RESET: 'REQUEST_RESET',
    SESSION_RESET: 'SESSION_RESET',

    // Show access revoked modal with only one option i.e., redirect to dashboard only
    REVOKE_ACCESS: 'REVOKE_ACCESS',

    // Patch request data
    EDIT_START: 'EDIT_START',
    EDIT_END: 'EDIT_END',
    REQUEST_RESUBMIT: 'REQUEST_RESUBMIT',

    // Others
    NOTES_EDIT: 'NOTES_EDIT'
};

export const SHARE_LINK_EVENT = {
    COPY: 1,
    RESET: 2,
    TOGGLE: 3
};
export const BLUEPRINT_MENU_ENUM = {
    PDF: 1,
    EXCEL: 2,
    INPUT: 3,
    EDIT: 4,
    DELETE: 5,
    EXPORT: 6,
    SHARE: 7,
    TICKET: 8,
    SHARE_WITH_ME: 9
};
export const BLUEPRINT_EXPORT_TYPE_ENUM = {
    PDF: 1,
    EXCEL: 2
};
export const ACCEPTED_BLUEPRINT_FILE_FORMAT = ['.pdf', '.dwg'];

// Icons colors
export const DISABLED_ICON_COLOR = '#aba9a7';
export const ENABLED_ICON_COLOR = '#4d4d4d';
export const PRIMARY_ICON_COLOR = '#4361EE';
export const SMALL_FOLDER_COLOR = '#808080';
export const FOLDER_ICON_COLOR = '#4361EE';
export const RED_ICON_COLOR = '#ff2e2e';
export const WHITE_ICON_COLOR = '#ffffff';

export const BP_PREFIX = 'construction/';

export const FOLDER_FILTER_TABS_ENUM = {
    ALL: 1,
    FOLDERS: 2,
    INDIVIDUAL_TAKEOFFS: 3
};

export const FOLDER_FILTER_TABS = {
    [FOLDER_FILTER_TABS_ENUM.ALL]: 'All',
    [FOLDER_FILTER_TABS_ENUM.FOLDERS]: 'Folders',
    [FOLDER_FILTER_TABS_ENUM.INDIVIDUAL_TAKEOFFS]: 'Individual Takeoffs'
};

export const SCALE_UNIT_ENUM = {
    CM: 1,
    M: 2,
    FT: 3,
    INCH: 4
};

export const SCALE_UNIT_NAMES = {
    [SCALE_UNIT_ENUM.CM]: 'cm',
    [SCALE_UNIT_ENUM.M]: 'm',
    [SCALE_UNIT_ENUM.FT]: 'ft',
    [SCALE_UNIT_ENUM.INCH]: 'inch'
};
export const REASON_FOR_CANCELLATION_ENUM = {
    INCORRECT_TAKEOFF: 1,
    INCORRECT_LOTBOUNDARY: 2,
    MISSED_ADDING_INSTRUCTION: 3,
    HIGHER_ETA: 4,
    OTHER: 5
};

export const CANCELLATION_OPTIONS = [
    'Incorrect takeoff time selected',
    'Incorrect lot boundary',
    'Missed adding instructions',
    'Higher ETA than expected',
    'Other'
];

export const SCALE_UNITS = [
    { key: 1, value: 'cm' },
    { key: 2, value: 'm' },
    { key: 3, value: 'ft' },
    { key: 4, value: 'inch' }
];

export const SHARED_VIEW_PATHNAME = {
    AERIAL: '/shared-view',
    BLUEPRINT: '/blueprint/shared-view'
};

export const REPORT_GROUP_ENUM = {
    OTHERS: 1,
    LANDSCAPING: 2,
    PAVING: 3
};

export const REPORT_GROUP_TITLE = {
    [REPORT_GROUP_ENUM.OTHERS]: 'Others',
    [REPORT_GROUP_ENUM.LANDSCAPING]: 'Landscaping Takeoffs',
    [REPORT_GROUP_ENUM.PAVING]: 'Paving Takeoffs'
};

export const MEASUREMENT_SYSTEM_ENUM = {
    IMPERIAL: 1,
    METRIC: 2
};

export const IMPERIAL_ATTRIBUTE_UNIT_TYPES = {
    FT: 1,
    SQFT: 2,
    CUYD: 3,
    COUNT: 4,
    LB: 5,
    LB_TON: 6
};
export const METRIC_ATTRIBUTE_UNIT_TYPES = {
    M: 1,
    SQM: 2,
    M3: 3,
    COUNT: 4,
    KG: 5,
    KG_TON: 6
};

export const IMPERIAL_ATTRIBUTE_UNIT_NAMES = {
    [IMPERIAL_ATTRIBUTE_UNIT_TYPES.FT]: 'ft',
    [IMPERIAL_ATTRIBUTE_UNIT_TYPES.SQFT]: 'ft2',
    [IMPERIAL_ATTRIBUTE_UNIT_TYPES.CUYD]: 'yd3',
    [IMPERIAL_ATTRIBUTE_UNIT_TYPES.COUNT]: 'unit',
    [IMPERIAL_ATTRIBUTE_UNIT_TYPES.LB]: 'lb',
    [IMPERIAL_ATTRIBUTE_UNIT_TYPES.LB_TON]: 'tn'
};
export const IMPERIAL_ATTRIBUTE_UNIT_NAMES_V2 = {
    [IMPERIAL_ATTRIBUTE_UNIT_TYPES.FT]: 'ft',
    [IMPERIAL_ATTRIBUTE_UNIT_TYPES.SQFT]: 'ft2',
    [IMPERIAL_ATTRIBUTE_UNIT_TYPES.CUYD]: 'yd3',
    [IMPERIAL_ATTRIBUTE_UNIT_TYPES.COUNT]: 'count',
    [IMPERIAL_ATTRIBUTE_UNIT_TYPES.LB]: 'lb',
    [IMPERIAL_ATTRIBUTE_UNIT_TYPES.LB_TON]: 'tn'
};
export const METRIC_ATTRIBUTE_UNIT_NAMES = {
    [METRIC_ATTRIBUTE_UNIT_TYPES.M]: 'm',
    [METRIC_ATTRIBUTE_UNIT_TYPES.SQM]: 'm2',
    [METRIC_ATTRIBUTE_UNIT_TYPES.M3]: 'm3',
    [METRIC_ATTRIBUTE_UNIT_TYPES.COUNT]: 'unit',
    [METRIC_ATTRIBUTE_UNIT_TYPES.KG]: 'kg',
    [METRIC_ATTRIBUTE_UNIT_TYPES.KG_TON]: 't'
};
export const METRIC_ATTRIBUTE_UNIT_NAMES_V2 = {
    [METRIC_ATTRIBUTE_UNIT_TYPES.M]: 'm',
    [METRIC_ATTRIBUTE_UNIT_TYPES.SQM]: 'm2',
    [METRIC_ATTRIBUTE_UNIT_TYPES.M3]: 'm3',
    [METRIC_ATTRIBUTE_UNIT_TYPES.COUNT]: 'count',
    [METRIC_ATTRIBUTE_UNIT_TYPES.KG]: 'kg',
    [METRIC_ATTRIBUTE_UNIT_TYPES.KG_TON]: 't'
};

// this is used in assembly material, here lb is not required
export const IMPERIAL_ATTRIBUTE_UNIT = [
    { name: 'ft', value: IMPERIAL_ATTRIBUTE_UNIT_TYPES.FT },
    { name: 'ft2', value: IMPERIAL_ATTRIBUTE_UNIT_TYPES.SQFT },
    { name: 'yd3', value: IMPERIAL_ATTRIBUTE_UNIT_TYPES.CUYD },
    { name: 'count', value: IMPERIAL_ATTRIBUTE_UNIT_TYPES.COUNT }
];
// this is used in assembly material, here kg is not required
export const METRIC_ATTRIBUTE_UNIT = [
    { name: 'm', value: METRIC_ATTRIBUTE_UNIT_TYPES.M },
    { name: 'm2', value: METRIC_ATTRIBUTE_UNIT_TYPES.SQM },
    { name: 'm3', value: METRIC_ATTRIBUTE_UNIT_TYPES.M3 },
    { name: 'count', value: METRIC_ATTRIBUTE_UNIT_TYPES.COUNT }
];
export const FEATURE_MODES = {
    CREATING: 1,
    EDITING: 2,
    VIEWING: 3
};

// Measurement view Options & enum
export const MEASUREMENT_VIEW_ENUM = {
    WORKABLE_SHEET_LEVEL: 1,
    TAKEOFF_LEVEL: 2
};
export const MEASUREMENT_TABLE_MODE = {
    VIEW: 1,
    EDIT: 2
};
export const MEASUREMENT_VIEW_OPTIONS = {
    [MEASUREMENT_VIEW_ENUM.WORKABLE_SHEET_LEVEL]: {
        name: 'Workable sheet',
        value: MEASUREMENT_VIEW_ENUM.WORKABLE_SHEET_LEVEL,
        description: 'This view shows the measurements for the selected workable sheet only.'
    },
    [MEASUREMENT_VIEW_ENUM.TAKEOFF_LEVEL]: {
        name: 'Takeoff Aggregate',
        value: MEASUREMENT_VIEW_ENUM.TAKEOFF_LEVEL,
        description: 'This view shows the measurements for the entire takeoff.'
    }
};

// Concurrency
export const CONCURRENCY_UNIT_PRICE = 99;
export const PURCHASE_CONCURRENCY_CAP = 4;
export const CONCURRENCY_MODE = {
    AERIAL: 1,
    BLUEPRINT: 2
};

export const CONCURRENCY_MODE_NAME = {
    [CONCURRENCY_MODE.AERIAL]: 'aerial',
    [CONCURRENCY_MODE.BLUEPRINT]: 'construction'
};

// correction Type

export const CORRECTION_TYPE_ENUM = {
    CORRECTION: 1,
    NEW_SHEET: 2,
    ADDENDUM: 3
};
export const LABEL_ACTIONS = {
    CREATED: 0,
    EDITED: 1,
    DELETED: 2,
    MOVED: 3
};

export const OUTPUT_TYPES = {
    OUTPUT: 'outputs',
    ARROWS: 'arrows',
    LABELS: 'labels',
    ICONS: 'icons',
    MEASUREMENTS: 'measure_tool',
    PARCEL: 'parcel'
};

export const TAG_ASSIGN_MODE = {
    FEATURE_LEVEL: 1,
    GEOMETRY_LEVEL: 2
};

export const INITIAL_DATA = 'undoState';
export const IMPERIAL_AREA_MULTIPLIER = 10.764;
export const IMPERIAL_LENGTH_MULTIPLIER = 3.281;

export const defaultFeatureData = {
    name: '',
    description: '',
    attach_all_worksheets: false,
    measurement_type: null,
    isExistingFeature: false,
    featureId: null,
    geometry: null,
    feature_id: null,
    assemblies: [],
    is_assembly_feature: false,
    catalog_info: null,
    attributes: [],
    attribute_based_measurements: []
};
export const toolTipMergeZone = [
    'Click on highlighted zones to toggle selection. Blue indicates selected zones, and red indicates unselected zones.',
    'Click "Save" to confirm and initiate the merge. Ensure your selections are accurate, as the action is irreversible.'
];
export const toolTipCreateZone = [
    'Choose the Zone tool (line/polygon) from the dropdown menu.',
    'For Zone line tool: Click the first point on the map outside the lot boundary, then click a second point outside the lot boundary. Double-click to complete the drawing. Click Save.',
    'For Zone polygon tool: Draw a polygon by placing points on the map. Double-click to finish the drawing.Click Save.',
    'To edit or delete zone, click the three dots next to each zone. The three dots will be visible by clicking on disable editing.'
];
export const toolTipEditZone = ['Click on the zone boundary.', 'Move the point of the vertices.', 'Click on save.'];

export const PHONE = '+1 407 955 5627';

export const MAX_TAGTYPES = 20;
export const MAX_TAGS = 50;
export const MAX_SUBTAGS = 60;

/* eslint-disable */
export const aerialShapelyScript = `
    from shapely import ops
    from shapely.geometry import shape, mapping
    from shapely.validation import make_valid
    from pyproj import CRS, Transformer 
    from js import layers, parcel_geojson, is_metric_system
    
    def get_parcel_transform(shape_geom):
        from_crs = CRS.from_epsg(4326)
        to_crs = CRS(
            proj="aea",
            lat_1=shape_geom.bounds[1],
            lat_2=shape_geom.bounds[3],
        )
        transform_obj = Transformer.from_crs(from_crs, to_crs, always_xy=True)
        return transform_obj

    def get_geojson_feature(geometry):
        geojson_feat = {"type": "Feature", "geometry": mapping(geometry), "properties": {}}
        return geojson_feat

    def get_valid_geoms(shapely_geom):
        shapely_geoms = []
        valid_shape = make_valid(shapely_geom)
        if ("Multi" + geom_type) == valid_shape.geom_type:
            shapely_geoms = list(make_valid(shape_geom).geoms)
        elif geom_type == valid_shape.geom_type:
            shapely_geoms = [valid_shape]
        elif valid_shape.geom_type == "GeometryCollection":
            for geometry in valid_shape.geoms:
                if geometry.geom_type == geom_type:
                    shapely_geoms.append(geometry)
                if geometry.geom_type == "Multi"+ geom_type:
                    for polygon in geometry.geoms:
                        shapely_geoms.append(polygon)
        else:
            shapely_geoms = []
        return shapely_geoms

    boundary_shape = shape(parcel_geojson.get("features")[0].get("geometry"))
    transform_obj = get_parcel_transform(boundary_shape)
    
    for layer in layers:
        feat_geojson = layer.get("output_geojson")
        measurements = layer.get("measurements")
        layerId = layer.get("id")
        measurement_names = {measurement.get("name"):0 for measurement in measurements}
        area_multiplier, length_multiplier = (1,1) if is_metric_system else (10.764, 3.281)
        features = feat_geojson.get("features")
        new_features = []
        k=0
        for feature in features:
            json_geom = feature.get("geometry")
            if not json_geom:
                continue
            shape_geom = shape(json_geom)
            geom_type = shape_geom.geom_type
            shape_geoms = [shape_geom]
            if not shape_geom.is_valid:
                shape_geoms = get_valid_geoms(shape_geom)
            for valid_geom in shape_geoms:
                new_feature = get_geojson_feature(valid_geom)
                projected_geom = ops.transform(transform_obj.transform, valid_geom)
                properties = {"id":k+1}
                if "area" in measurement_names:
                    properties["area"] = round(projected_geom.area*area_multiplier,2)
                    measurement_names["area"] += properties["area"]
                    properties["perimeter"] = round(projected_geom.length*length_multiplier,2)
                    measurement_names["perimeter"] += properties["perimeter"]
                    properties["layerId"] = layerId
                if "length" in measurement_names:
                    properties["length"] = round(projected_geom.length*length_multiplier,2)
                    measurement_names["length"] += properties["length"]
                    properties["layerId"] = layerId
                if "count" in measurement_names:
                    measurement_names["count"]=k+1
                new_feature["properties"] = properties
                new_features.append(new_feature)
                k+=1
        feat_geojson["features"]=new_features
        for measurement in measurements:
            measurement["value"]= round(measurement_names[measurement.get("name")])
            
    layers
`;

export const blueprintShapelyScript = `
    from shapely.geometry import Polygon, shape, mapping, LineString
    from js import layers, scale, is_metric_system
    import math
    import re
    def meter_square_to_square_feet(value, precision):
        return round(value * 10.764, precision)
    
    def meter_to_feet(value, precision):
        return round(value * 3.281, precision)
    
    def actual_measurements(measurement_name, value, scale, dpi):
        px_to_mm = 25.4/dpi
        factor = px_to_mm * 0.001 * float(scale)
        if measurement_name == "area":
            value *= pow(factor,2)
        else:
            value *= factor
        return value
    
    def get_coordinates_tuple(json_coordinates, geometry):
        coordinates_tuple_list = []
        if geometry == "Polygon":
            for coordinates in json_coordinates:
                    for coordinate in coordinates:
                        tuple_list = (coordinate[0], coordinate[1])
                        coordinates_tuple_list.append(tuple_list)
        else:
            for coordinate in json_coordinates:
                    tuple_list = (coordinate[0], coordinate[1])
                    coordinates_tuple_list.append(tuple_list)
        return coordinates_tuple_list
    
    def calculate_area_and_perimeter(coordinates_tuple_list, scale, area=False):
        ext = coordinates_tuple_list[0]
        int = coordinates_tuple_list[1:]
        polygonInteriors = []
        for i in range(len(int)):
            polygonInteriors.append(int[i])
        newPolygon = Polygon(ext, [[pt for pt in inner] for inner in polygonInteriors])
        if area:
            poly_area = actual_measurements("area", newPolygon.area, scale, 200)
            return meter_square_to_square_feet(poly_area, 2)
        else:
            poly_perimeter = actual_measurements("perimeter", newPolygon.length, scale, 200)
            return meter_to_feet(poly_perimeter, 2)
    
    def get_geojson_feature(geometry, properties=None, convert_to_geojson=True):
        geojson_feat = {
            "type": "Feature",
        }
        if convert_to_geojson:
            geojson_feat["geometry"] = mapping(geometry)
        else:
            geojson_feat["geometry"] = geometry
        geojson_feat["properties"] = properties
    
        return geojson_feat
    
    def get_measurement_value(measurement_name, coordinates_tuple_list, scale):
        if measurement_name == "area":
            return calculate_area_and_perimeter(coordinates_tuple_list, scale, area=True)
        elif measurement_name == "perimeter":
            return calculate_area_and_perimeter(coordinates_tuple_list, scale)
        else:
            return calculate_length(coordinates_tuple_list, scale)
    
    def get_geojson_feature_collection(geojson_features):
        feature_collection = {
            "type": "FeatureCollection",
            "features": [],
        }
        feature_collection["features"] = geojson_features
        return feature_collection
    
    def calculate_length(coordinates_tuple_list, scale):
        line = LineString(coordinates_tuple_list)
        shape_perimeter_length =  actual_measurements("length", line.length, scale, 200)
        return meter_to_feet(shape_perimeter_length, 2)
    
    def get_area_length(shape_geom, scale):
        if shape_geom["type"] == "Polygon" and shape_geom.get("coordinates"):
            coordinates_tuple_list = shape_geom.get("coordinates")
            measurement_value = get_measurement_value("area", coordinates_tuple_list, scale)
            
        elif shape_geom["type"] == "LineString" and shape_geom.get("coordinates"):
            coordinates_tuple_list = get_coordinates_tuple(shape.get("coordinates"), shape_geom["type"])
            measurement_value = get_measurement_value("length", coordinates_tuple_list, scale)
        return measurement_value
    
    def calculate_metrics_construction(measurement_names, feat_geojson, scale, measurements, geometry_type):
        features = feat_geojson.get("features")
        unit = {measurement.get("unit") for measurement in measurements}
        area_multiplier = 10.764 if "sqm" in unit else 1
        length_multiplier = 3.281 if "m" in unit else 1
        geojson_features = []
    
        for idx, feature in enumerate(features):
            json_geom = feature.get("geometry")
            geom_properties = feature.get("properties")
            
            # getting coordinates in tuple list format
    
            if json_geom["type"] == "Polygon" and json_geom.get("coordinates"):
                coordinates_tuple_list = json_geom.get("coordinates")
                properties = {"id": idx + 1}
            elif json_geom["type"] == "LineString" and json_geom.get("coordinates"):
                coordinates_tuple_list = []
                coordinates_tuple_list = get_coordinates_tuple(json_geom.get("coordinates"), json_geom["type"])
                properties = {"id": idx + 1}
            elif json_geom["type"] == "Point" and  json_geom.get("coordinates"):
                properties = {"id": idx + 1}
            else:
                continue
            
            shape_geom = shape(json_geom)
            # For point feature, metric is not required to be calculated.
            if measurement_names and geometry_type != 1:
                for measurement_name in measurement_names:
                    measurement_name = measurement_name.lower()
                    if geometry_type == 4:
                        measurement_value = get_abstract_feature_values(feature, measurement_name)
                    else:
                        measurement_value = get_measurement_value(measurement_name, coordinates_tuple_list, scale)
                    if measurement_name == "area":
                        measurement_value/=area_multiplier
                    elif measurement_name in ["perimeter", "length"]:
                        measurement_value/=length_multiplier
                    properties[measurement_name] = measurement_value

            tags_info = geom_properties.get('tags_info', {})
            properties['tags_info'] = tags_info
            callout_properties = {
                'Polygon': ['edit_area', 'edit_perimeter'],
                'LineString': ['edit_length']
            }
            callout_values = {key: geom_properties.get(key, None) for key in callout_properties.get(json_geom["type"], [])}
            properties.update(callout_values)
            geojson_geom = get_geojson_feature(shape_geom, properties)
            geojson_features.append(geojson_geom)
        feature_collection = get_geojson_feature_collection(geojson_features)
        return feature_collection
    
    def calculate_assemblies(output, assembly_key):
        noOfFeature = len(output["output_geojson"]["features"])
        for assembly in output["assemblies"]:
            assembly_value = float(assembly["value"]);
            feat_measurement = output["measurements"]
            filtered_dic  = [dic['value'] for dic in feat_measurement if dic['name'] == assembly_key]
            cal_value = float(filtered_dic[0]) if filtered_dic else 0.0

            if assembly["is_scale_linearly"]:
                assembly["measurement"] = round(float(assembly_value) * cal_value, 2)
            else:
                assembly["measurement"] = round(assembly_value, 2)

    def get_splitted_formula(formula) -> list:
        try:
            formula_splitted = re.findall(r"\s*([A-Za-z_]+|\d+\.\d+|\d+|\+|\-|\*{2}|\*|/|%|\^|\(|\))\s*", formula)
            return formula_splitted;
        except Exception as e:
            print("exception",e);
        return "";

    def calculate(formula,**kwargs):
        return eval(formula, kwargs)

    def evaluate(formula, aggregated_data):
        is_evaluation_successful = True
        value = 0
        try:
            value = calculate(formula=formula,**aggregated_data)
        except Exception as e:
            is_evaluation_successful = False

        return value, is_evaluation_successful
    


    def calculate_attribute_based_measurement(output, is_metric_system):
        
        attributes_data = output.get("attributes",[])
        measurement_data = output.get("measurements",[])
        attributes_measurements = output.get("attribute_based_measurements",[])
        

        # Initialize dictionaries
        aggregated_data = {}
        attribute_data = {}
        agg_measurements = {}
        # Process attributes_data

        if attributes_data is not None:
            for attr in attributes_data:
                unit = attr.get('unit')
                name = attr.get('name')
                value = attr.get('value')

                if unit == 10:
                    aggregated_data[name] = eval(value)
                elif unit == 11:
                    aggregated_data[name] = value
                else:
                    aggregated_data[name] = float(value)

            
        # Process measurement_data
        if measurement_data is not None:
            for measurement in measurement_data:
                name = measurement.get('name')
                value = measurement.get('value')
                aggregated_data[name] = float(value)

        # Process attributes_measurements
        if attributes_measurements is not None:
            for measurements in attributes_measurements:
                name = measurements.get('name')
                formula = measurements.get('measurement_formula')
                imperial_factor = measurements.get('imperial_factor')
                metric_factor = measurements.get('metric_factor')
                if not measurements.get('is_legacy_formula'):
                    value, _ = evaluate(formula, aggregated_data)
                    if is_metric_system:
                        value = float(value) * float(metric_factor)
                    else:
                        value = float(value) * float(imperial_factor)
                else:
                    value = measurements.get('value')
                    
                measurements['value'] = value

            
    def create_measurements(outputs, scale, is_metric_system):
        for output in outputs:
            geometry_type = output["feature"].get("geometry_type")
            assembly_key = ""
            if geometry_type == 1:
                measurements_list = ["count"]
                assembly_key = "count"
            elif geometry_type == 2:
                measurements_list = ["length"]
                assembly_key = "length"
            elif geometry_type == 3:
                measurements_list = ["area", "perimeter"]
                assembly_key = "area"
            else:
                measurements_list = output["measurements"]
                measurements_list = {measurement.get("name") for measurement in output["measurements"]}
                measurements_list_strict = [measurement.get("name") for measurement in output["measurements"]]
                assembly_key = measurements_list_strict[0]

            geometry_type = output["feature"].get("geometry_type")
            output["output_geojson"] = calculate_metrics_construction(measurements_list, output["output_geojson"], scale, output["measurements"], geometry_type)
            calculate_aggregate(output=output, geometry_type=geometry_type, measurements_list=measurements_list)
            calculate_assemblies(output=output, assembly_key=assembly_key)
            calculate_attribute_based_measurement(output=output, is_metric_system = is_metric_system)
    
    def calculate_aggregate_metrics(measurement_names, feat_geojson):
        features = feat_geojson.get("features")
        agg_measurement = {}
        for measurement_name in measurement_names:
            agg_measurement[measurement_name.lower()] = 0
    
        for feature in features:
            properties = feature.get("properties")
            for measurement_name in measurement_names:
                measurement_name = measurement_name.lower()
                val = properties.get(measurement_name, 0)
                callout_val = properties.get('edit_' + measurement_name)

                if callout_val is not None:
                    agg_measurement[measurement_name] += float(callout_val)
                else:
                    agg_measurement[measurement_name] += float(val)
        return agg_measurement
    
    def get_multiplier(unit, name):
        if name == "area":
            return 10.764 if unit == "sq ft" else 1
        elif name == "length" or name == "perimeter":
            return 3.281 if unit == "ft" else 1
        else:
            return 1
    
    def calculate_aggregate(output, geometry_type, measurements_list=None):
        """Calculate aggregate measurement metrics from individual feature metrics in output object's geojson.
    
        Args:
            output (apps.construction.models.ConstructionOutput): ConstructionOutput object for which aggregate metrics needs to
            be calculated
            geometry_type (int): geometry type of the output object
            measurements_list (list, optional): Measurement types for the output. Defaults to None.
        """    
        output_geojson = output["output_geojson"]
        # Special handling for count as it is only an aggregate metric and not per feature metric
        if geometry_type not in  [1]:
            agg_measurement_value_dict = calculate_aggregate_metrics(measurements_list, output_geojson)
        else:
            features = [feat for feat in output_geojson.get("features") if feat.get("geometry", {}).get("coordinates")]
            agg_measurement_value_dict = {"count": len(features)}
        for measurement in measurements_list:
            agg_val = agg_measurement_value_dict.get(measurement.lower())
        measurements = output.get("measurements")
        
        for measurement in measurements:
            multiplier = 1
            measurement["value"]=agg_measurement_value_dict[measurement.get("name")]*multiplier
            
    def get_abstract_feature_values(feature_json, measurement_name):
        geojson_properties = feature_json.get("properties")
        
        # Keeping default value for the feature as 0
        measurement_value = 0
    
        # If values are coming from the user, the values can also be in METRIC system.
        if measurement_name in geojson_properties.keys():
            measurement_value = float(geojson_properties.get(measurement_name))
        return measurement_value
    
    create_measurements(layers, scale, is_metric_system)

    layers
`;

export const areaScript = `
    import pyproj
    from shapely import ops
    from shapely.geometry import shape
    from js import parcel_geojson, is_metric_system

    def get_parcel_transform(shape_geom):
        from_crs = pyproj.CRS.from_epsg(4326)
        to_crs = pyproj.CRS(
            proj="aea",
            lat_1=shape_geom.bounds[1],
            lat_2=shape_geom.bounds[3],
        )
        transform_obj = pyproj.Transformer.from_crs(from_crs, to_crs, always_xy=True)
        return transform_obj


    class ShapeProjection(object):
        def __init__(self, parcel_shape):
            self.parcel_shape = parcel_shape
            self.projection_transform_obj = get_parcel_transform(shape_geom=parcel_shape)

        def get_projected_shape(self, geom_shape=None):
            if not geom_shape:
                geom_shape = self.parcel_shape
            projected_geom = ops.transform(
                self.projection_transform_obj.transform,
                geom_shape,
            )
            return projected_geom

    def calculate_area(shape_geom, parcel_proj=None):
        if parcel_proj:
            geom = parcel_proj.get_projected_shape(shape_geom)
        else:
            geom = ShapeProjection(shape_geom).get_projected_shape()
        poly_area = round(geom.area, 2)
        return poly_area

    def parcel_area(parcel_geojson, is_metric_system):
        features = parcel_geojson.get("features")
        parcel_geometry = features[0].get("geometry")
        shape_geom = shape(parcel_geometry)
        area = calculate_area(shape_geom)

        if(is_metric_system):
            return area
        else:
            return area * 10.764

    finalArea = {"area": parcel_area(parcel_geojson, is_metric_system)}
    
    finalArea
`;

export const selectNonHighLightLayers = (name: $TSFixMe) =>
    [MAP_LAYERS.PARCEL, MAP_LAYERS.ICON, MAP_LAYERS.LABELS, MAP_LAYERS.NOTES, MAP_LAYERS.ZONE].includes(name);

export const deleteNonHighLightLayers = (name: $TSFixMe) =>
    [MAP_LAYERS.PARCEL, MAP_LAYERS.NOTES, MAP_LAYERS.ZONE].includes(name);
export const moveNonHighLightLayers = (name: $TSFixMe) =>
    [MAP_LAYERS.PARCEL, MAP_LAYERS.NOTES, MAP_LAYERS.ZONE].includes(name);
export const tagsNonHighLightLayers = (name: $TSFixMe) =>
    [
        MAP_LAYERS.PARCEL,
        MAP_LAYERS.ICON,
        MAP_LAYERS.LABELS,
        MAP_LAYERS.NOTES,
        MAP_LAYERS.ZONE,
        MAP_LAYERS.ARROW
    ].includes(name);

export const REXEL_USER = 'rexel';
export const BEAM_USER = 'ibeam';

export const BASE_LAYER_ID = 'baseLayer';

export const CHECKBOX_STATUS = Object.freeze({
    CHECKED: 1,
    INDETERMINATE: 2,
    DISABLED: 3
});
export const addFeatureTools = [
    TOOLS_ID.ADD_FEATURE,
    TOOLS_ID.ADD_RECTANGLE,
    TOOLS_ID.ADD_CIRCLE,
    TOOLS_ID.ADD_CURVE,
    TOOLS_ID.FREE_HAND,
    TOOLS_ID.FILL_TOOL
];

export const COMPANY_COUNT = '600+';

export const FILL_PATTERNS_ENUM = {
    NO_PATTERN: 1,
    DIAMOND: 2,
    ZIGZAG: 3,
    HONEYCOMB: 5,
    CHESS: 6,
    GRID: 7,
    DOTS: 8,
    DIAGONAL_LINE: 9
};

export const FILL_PATTERNS = {
    [FILL_PATTERNS_ENUM.NO_PATTERN]: {
        image: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/block_FILL0_wght400_GRAD0_opsz24%20124px.png',
        id: FILL_PATTERNS_ENUM.NO_PATTERN
    },
    [FILL_PATTERNS_ENUM.DIAMOND]: {
        image: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/diamond%20grid.png',
        id: FILL_PATTERNS_ENUM.DIAMOND
    },
    [FILL_PATTERNS_ENUM.ZIGZAG]: {
        image: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/zigzig%20grid.png',
        id: FILL_PATTERNS_ENUM.ZIGZAG
    },
    [FILL_PATTERNS_ENUM.HONEYCOMB]: {
        image: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/hexagon%20grid.png',
        id: FILL_PATTERNS_ENUM.HONEYCOMB
    },
    [FILL_PATTERNS_ENUM.CHESS]: {
        image: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/checkers.png',
        id: FILL_PATTERNS_ENUM.CHESS
    },
    [FILL_PATTERNS_ENUM.GRID]: {
        image: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/square%20grid.png',
        id: FILL_PATTERNS_ENUM.GRID
    },
    [FILL_PATTERNS_ENUM.DOTS]: {
        image: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/dot%20grid.png',
        id: FILL_PATTERNS_ENUM.DOTS
    },
    [FILL_PATTERNS_ENUM.DIAGONAL_LINE]: {
        image: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/diagonal%20grid.png',
        id: FILL_PATTERNS_ENUM.DIAGONAL_LINE
    }
};

export const PLUS_ICON = 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/svgs/output/plus.svg';
export const MINUS_ICON = 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/svgs/output/minus.svg';

export const IMAGERY_SELECTION_ENUM = {
    STICHED_IMAGE: 1,
    COMPLETE_NEARMAP_IMAGE: 2
};

export const IMAGERY_SELECTION_TOOLTIP = {
    [IMAGERY_SELECTION_ENUM.STICHED_IMAGE]: 'Budget friendly, with only lot areas drawn in HD quality',
    [IMAGERY_SELECTION_ENUM.COMPLETE_NEARMAP_IMAGE]: 'Higher cost, and the entire property will be in HD quality'
};
export const BP_MEASUREMENT_TABLE_STATUS = Object.freeze({
    IDLE: 1,
    LOADING: 2,
    ERROR: 3
});

export const MEASUREMENT_VIEW_TYPE = Object.freeze({
    WORKABLE: 1,
    TAKEOFF: 2
});

export const MEASUREMENT_TABLE_VIEW = {
    ZONAL_VIEW: 'Zonal view',
    DEFAULT_VIEW: 'Default view',
    GROUP_VIEW: 'Grouped view'
};

export const MAX_PAYLOAD_SIZE = 15000000;

export const ON_SITE_LINK_MODAL = {
    PLAY_STORE_LINK: 'https://play.google.com/store/apps/details?id=ai.attentive.automeasure',
    APP_STORE_LINK: 'https://apps.apple.com/ph/app/attentive-onsite/id6443751919',
    TITLE: 'Carry your takeoffs on your palm!',
    CONTENT: 'Download our mobile application to access your takeoffs anywhere, anytime.'
};

export const FALCON_CLIENT_LOGOS = [
    {
        imgUrl: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/uslawns.jpeg',
        alt: 'US Lawns Logo'
    },
    { imgUrl: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/merit.png', alt: 'Merit Logo' },
    {
        imgUrl: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/maldonado_logo.png',
        alt: 'Maldonado Logo'
    },
    { imgUrl: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/juniper.png', alt: 'Juniper Logo' },
    { imgUrl: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/beary.jpeg', alt: 'Beary Logo' }
];

export const BEAM_CLIENT_LOGOS = [
    {
        imgUrl: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/6548a2bd6f5c62243aa1e89b_Louis-Ptak-construction.png',
        alt: 'Louis Ptak Construction Logo'
    },
    {
        imgUrl: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/6548a2bd6f5c62243aa1e89f_barlow-concrete-construction-Inc.png',
        alt: 'barlow Concrete Construction Logo'
    },
    {
        imgUrl: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/6548a2bd6f5c62243aa1e89e_Monument-Homebuilders.png',
        alt: 'Monument Homebuilders Logo'
    },
    {
        imgUrl: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/65604d60dac890c100755e01_may-residential.jpg',
        alt: 'May Residential Logo'
    },
    {
        imgUrl: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/6548a2bd6f5c62243aa1e89c_c-and-c-asphalt-concrete-and-concrete-constructions.png',
        alt: 'C & C Ashalt Logo'
    }
];

export const POINT_SHAPES_ENUM = {
    NO_PATTERN: 1,
    SQUARE: 2,
    TRIANGLE: 3,
    CIRCLE: 4,
    DECIDUOUS_TREE: 5,
    PALM_TREE: 6,
    ADD: 7
};

export const POINT_SHAPES = {
    [POINT_SHAPES_ENUM.NO_PATTERN]: {
        id: POINT_SHAPES_ENUM.NO_PATTERN,
        image: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/block_FILL0_wght400_GRAD0_opsz24%20124px.png'
    },
    [POINT_SHAPES_ENUM.SQUARE]: {
        id: POINT_SHAPES_ENUM.SQUARE,
        image: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/Rectangle%20(1).svg'
    },
    [POINT_SHAPES_ENUM.TRIANGLE]: {
        id: POINT_SHAPES_ENUM.TRIANGLE,
        image: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/Triangle%20(3).svg'
    },
    [POINT_SHAPES_ENUM.CIRCLE]: {
        id: POINT_SHAPES_ENUM.CIRCLE,
        image: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/Ellipse%2016.svg'
    },
    [POINT_SHAPES_ENUM.DECIDUOUS_TREE]: {
        id: POINT_SHAPES_ENUM.DECIDUOUS_TREE,
        image: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/Vector%20(1).svg'
    },
    [POINT_SHAPES_ENUM.PALM_TREE]: {
        id: POINT_SHAPES_ENUM.PALM_TREE,
        image: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/Frame%201000006917%20(1).svg'
    },
    [POINT_SHAPES_ENUM.ADD]: {
        id: POINT_SHAPES_ENUM.ADD,
        image: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/add%20(1).svg'
    }
};

export const MENU_ITEMS_TITLE = Object.freeze({
    CUT: 'Cut',
    COPY: 'Copy',
    PASTE: 'Paste',
    LOCATE: 'Locate',
    MODIFY_CALLOUT: 'Modify callout',
    FLIP: 'Flip',
    HORIZONTALLY: 'Horizontally',
    VERTICALLY: 'Vertically',
    ROTATE: 'Rotate',
    CLOCKWISE: 'Clockwise 90°',
    COUNTER_CLOCKWISE: 'Counter clockwise 90°',
    DELETE: 'Delete'
});

export const AERIAL_NOTES_CATEGORY_ENUM = {
    WEED_ISSUE: 1,
    ANCILLARY_WORK: 2,
    IRRIGATION: 3,
    DAMAGE: 4,
    NOTES: 5
};

export const AERIAL_NOTES_CATEGORY = {
    [AERIAL_NOTES_CATEGORY_ENUM.WEED_ISSUE]: 'Weed Issue',
    [AERIAL_NOTES_CATEGORY_ENUM.ANCILLARY_WORK]: 'Ancillary Work',
    [AERIAL_NOTES_CATEGORY_ENUM.IRRIGATION]: 'Irrigation',
    [AERIAL_NOTES_CATEGORY_ENUM.DAMAGE]: 'Damage',
    [AERIAL_NOTES_CATEGORY_ENUM.NOTES]: 'Notes'
};

export const AERIAL_NOTES_CATEGORY_COLOR = {
    [AERIAL_NOTES_CATEGORY_ENUM.WEED_ISSUE]: '#355e3b',
    [AERIAL_NOTES_CATEGORY_ENUM.ANCILLARY_WORK]: '#369d1a',
    [AERIAL_NOTES_CATEGORY_ENUM.IRRIGATION]: '#786C57',
    [AERIAL_NOTES_CATEGORY_ENUM.DAMAGE]: '#FF0000',
    [AERIAL_NOTES_CATEGORY_ENUM.NOTES]: '#FFFF00'
};

export const BPT_NOTES_CATEGORY_ENUM = {
    NOTES: 1,
    INTERNAL_NOTES: 2,
    EXTERNAL_NOTES: 3
};

export const BPT_NOTES_CATEGORY = {
    [BPT_NOTES_CATEGORY_ENUM.NOTES]: 'Notes',
    [BPT_NOTES_CATEGORY_ENUM.INTERNAL_NOTES]: 'Internal Notes',
    [BPT_NOTES_CATEGORY_ENUM.EXTERNAL_NOTES]: 'External Notes'
};

export const BPT_NOTES_CATEGORY_COLOR = {
    [BPT_NOTES_CATEGORY_ENUM.NOTES]: '#FFFF00',
    [BPT_NOTES_CATEGORY_ENUM.INTERNAL_NOTES]: '#3EC7C5',
    [BPT_NOTES_CATEGORY_ENUM.EXTERNAL_NOTES]: '#5C7CED'
};

export const NOTES_STATUS = {
    NEW: 1,
    UNRESOLVED: 2,
    RESOLVED: 3
};

export const NOTES_ACCESS_ENUM = {
    ALL: 1,
    SHARED_USERS: 2,
    ONLY_ME: 3
};

export const NOTES_ACCESS_TYPES = {
    [NOTES_ACCESS_ENUM.ALL]: 'Anyone',
    [NOTES_ACCESS_ENUM.SHARED_USERS]: 'Shared access users',
    [NOTES_ACCESS_ENUM.ONLY_ME]: 'Only Me'
};

export const TYPE_TO_ICON = {
    [EXPORT_OUTPUT_ENUM.LINK]:
        'https://storage.googleapis.com/falcon-shared-images-front-end/assets/svgs/export-modal/share-link.svg',
    [EXPORT_OUTPUT_ENUM.PDF]:
        'https://storage.googleapis.com/falcon-shared-images-front-end/assets/svgs/export-modal/pdf.svg',
    [EXPORT_OUTPUT_ENUM.EXCEL]:
        'https://storage.googleapis.com/falcon-shared-images-front-end/assets/svgs/export-modal/xls.svg',
    [EXPORT_OUTPUT_ENUM.JPEG]:
        'https://storage.googleapis.com/falcon-shared-images-front-end/assets/svgs/export-modal/jpg.svg',
    [EXPORT_OUTPUT_ENUM.CSV]:
        'https://storage.googleapis.com/falcon-shared-images-front-end/assets/svgs/export-modal/csv.svg',
    [EXPORT_OUTPUT_ENUM.ALL]:
        'https://storage.googleapis.com/falcon-shared-images-front-end/assets/svgs/all_26_10_2023_b4cecbb1-3b1c-47b5-909f-508324796566.svg',
    [EXPORT_OUTPUT_ENUM.SCREENSHOT]:
        'https://storage.googleapis.com/falcon-shared-images-front-end/assets/screenshot-2-line.svg',
    [EXPORT_OUTPUT_ENUM.SUPPLEMENTARY]:
        'https://storage.googleapis.com/falcon-shared-images-front-end/assets/supplimentry_attachment.svg'
};

export const MEASURE_EXPORT_TYPES = [
    {
        id: EXPORT_OUTPUT_ENUM.LINK,
        name: 'Share link',
        icon: TYPE_TO_ICON[EXPORT_OUTPUT_ENUM.LINK]
    },
    {
        id: EXPORT_OUTPUT_ENUM.PDF,
        name: 'Export PDF file',
        icon: TYPE_TO_ICON[EXPORT_OUTPUT_ENUM.PDF]
    },
    {
        id: EXPORT_OUTPUT_ENUM.EXCEL,
        name: 'Export EXCEL file',
        icon: TYPE_TO_ICON[EXPORT_OUTPUT_ENUM.EXCEL]
    },
    {
        id: EXPORT_OUTPUT_ENUM.JPEG,
        name: 'Export JPEG file',
        icon: TYPE_TO_ICON[EXPORT_OUTPUT_ENUM.JPEG]
    },
    {
        id: EXPORT_OUTPUT_ENUM.ALL,
        name: 'Export All',
        icon: TYPE_TO_ICON[EXPORT_OUTPUT_ENUM.ALL]
    },
    {
        id: EXPORT_OUTPUT_ENUM.SCREENSHOT,
        name: 'Screenshot',
        icon: TYPE_TO_ICON[EXPORT_OUTPUT_ENUM.SCREENSHOT]
    },
    {
        id: EXPORT_OUTPUT_ENUM.SUPPLEMENTARY,
        name: 'Supplementary files',
        icon: TYPE_TO_ICON[EXPORT_OUTPUT_ENUM.SUPPLEMENTARY]
    }
];

export const NOTES_EXPORT_TYPES = [
    // {
    //     id: EXPORT_OUTPUT_ENUM.PDF,
    //     name: 'Export PDF file',
    //     icon: TYPE_TO_ICON[EXPORT_OUTPUT_ENUM.PDF]
    // },
    {
        id: EXPORT_OUTPUT_ENUM.EXCEL,
        name: 'Export Excel file',
        icon: TYPE_TO_ICON[EXPORT_OUTPUT_ENUM.EXCEL]
    }
];

export const MAX_MEDIA_SIZE = {
    IMAGE: 5,
    VIDEO: 30
};

export const NOTE_MEDIA_TYPE = {
    IMAGE: 1,
    VIDEO: 2
};

export const NOTE_SORT = {
    OLD_TO_NEW: 1,
    NEW_TO_OLD: 2
};

export const MARKUP_TOOLS = [
    { displayName: 'Arrows', payloadName: 'arrow' },
    { displayName: 'Measurement Lines', payloadName: 'measure' },
    { displayName: 'Labels', payloadName: 'label' },
    { displayName: 'Icons', payloadName: 'icon' },
    { displayName: 'Text Boxes', payloadName: 'label_box' }
];

export const LOGO = {
    COMPANY_LOGO: 1,
    TEAM_LOGO: 2
};
